import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useParams } from "react-router-dom";

import { initMeet } from "util/pouchAdapter";
import { startLoadingMeet, doneLoadingMeet } from "actions";

import Header from "app/meets/Header";
import SetupIndex from "./setup/SetupIndex";
import RegistrationIndex from "./registration/RegistrationIndex";
import RegistrationComplete from "./registration/RegistrationComplete";
import LiveFeedIndex from "./liveFeed/LiveFeedIndex";
import LifterIndex from "./lifter/LifterIndex";
import DivisionIndex from "./division/DivisionIndex";
import MembershipCheckerIndex from "./membershipChecker/MembershipCheckerIndex";
import TeamIndex from "./team/TeamIndex";
import ImportIndex from "./import/ImportIndex";
import LiftersIndex from "./lifters/LiftersIndex";
import WeighInEntryIndex from "./weighInEntry/WeighInEntryIndex";
import PaperworkIndex from "./paperwork/PaperworkIndex";
import DivisionsIndex from "./divisions/DivisionsIndex";
import RecordsIndex from "./records/RecordsIndex";
import RunIndex from "./run/RunIndex";
import BoardIndex from "./board/BoardIndex";
import DisplayIndex from "./display/DisplayIndex";
import ResultsIndex from "./results/ResultsIndex";
import RefIndex from "./ref/RefIndex";
import StatsIndex from "./stats/StatsIndex";
import RosterIndex from "./roster/RosterIndex";
import NotFound from "./NotFound";
import AuthRoute from "./AuthRoute";
import LogoIcon from "icons/LogoIcon";
import PasswordModal from "components/passwordModal/PasswordModal";
import RouteNotFound from "app/RouteNotFound";
import RackHeightsIndex from "./rackHeights/RackHeightsIndex";
import VirtualMeetSubmitIndex from "./virtualMeetSubmit/VirtualMeetSubmitIndex";
import ValidatorIndex from "./validator/ValidatorIndex";
import { useStatus } from "util/useStatus";
import { useMeet } from "util/useMeet";

const Meet = () => {
  const dispatch = useDispatch();
  const { meetId } = useParams<{ meetId: string }>();
  const meet = useMeet();
  const { isLoading, isNotFound, isSyncActive } = useStatus();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const passwordResolve = useRef<any>(null);

  const onFinishBulkCreateTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    initMeet(meetId);

    return () =>
      onFinishBulkCreateTimeoutRef.current &&
      clearTimeout(onFinishBulkCreateTimeoutRef.current);
  }, [meetId]);

  const onFinishBulkCreate = (count: number) => {
    // Hard to tell how long it takes to load bulk data. So wait an amount of time per item.
    // We want to not render child components so they don't have to respond to
    dispatch(startLoadingMeet(meetId));
    onFinishBulkCreateTimeoutRef.current = setTimeout(
      checkForSyncProgress,
      count * 100
    );
  };

  const checkForSyncProgress = () => {
    // Continue showing loading screen if sync is active after large data load.
    // Helps avoids bugs where user closes browser before sync finishes.
    if (isSyncActive) {
      onFinishBulkCreateTimeoutRef.current = setTimeout(
        checkForSyncProgress,
        200
      );
    } else {
      dispatch(doneLoadingMeet(meetId));
    }
  };

  const closePasswordModal = () => {
    passwordResolve.current = null;
    setIsPasswordModalOpen(false);
  };

  const openPasswordModal = () => {
    return new Promise((resolve) => {
      setIsPasswordModalOpen(true);
      passwordResolve.current = resolve;
    });
  };

  const onCancelPasswordModal = () => {
    passwordResolve.current = null;
  };

  const resolvePasswordPromise = (password: string) => {
    return (
      passwordResolve.current &&
      passwordResolve.current({
        password,
      })
    );
  };

  if (isNotFound) {
    return <NotFound />;
  }

  return (
    <div className="meet">
      <PasswordModal
        isOpen={isPasswordModalOpen}
        onRequestClose={closePasswordModal}
        onOk={resolvePasswordPromise}
        onCancel={onCancelPasswordModal}
        meetId={meet._id}
        meet={meet}
      />
      <Header openPasswordModal={openPasswordModal} />
      <div className="content">
        {!isLoading && (
          <Switch>
            <Route path="/meets/:meetId/" exact component={ResultsIndex} />
            <AuthRoute
              path="/meets/:meetId/setup"
              component={SetupIndex}
              openPasswordModal={openPasswordModal}
            />
            <Route
              path="/meets/:meetId/registration"
              component={RegistrationIndex}
            />
            <Route
              path="/meets/:meetId/registration-complete"
              component={RegistrationComplete}
            />
            <Route path="/meets/:meetId/liveFeed" component={LiveFeedIndex} />
            <AuthRoute
              path="/meets/:meetId/import"
              component={ImportIndex}
              onFinishBulkCreate={onFinishBulkCreate}
              openPasswordModal={openPasswordModal}
            />
            <Route path="/meets/:meetId/records" component={RecordsIndex} />
            <AuthRoute
              path="/meets/:meetId/lifters"
              component={LiftersIndex}
              onFinishBulkCreate={onFinishBulkCreate}
              openPasswordModal={openPasswordModal}
            />
            <AuthRoute
              path="/meets/:meetId/weighInEntry"
              component={WeighInEntryIndex}
              openPasswordModal={openPasswordModal}
            />
            <AuthRoute
              path="/meets/:meetId/membershipChecker"
              component={MembershipCheckerIndex}
              openPasswordModal={openPasswordModal}
            />
            <Route
              path="/meets/:meetId/lifter/:lifterId"
              component={LifterIndex}
            />
            <Route
              path="/meets/:meetId/division/:divisionId/weightClass/:weightClassId"
              component={DivisionIndex}
            />
            <Route path="/meets/:meetId/team/:teamName" component={TeamIndex} />
            <AuthRoute
              path="/meets/:meetId/paperwork"
              component={PaperworkIndex}
              openPasswordModal={openPasswordModal}
            />
            <Route path="/meets/:meetId/roster" component={RosterIndex} />
            <AuthRoute
              path="/meets/:meetId/divisions"
              component={DivisionsIndex}
              onFinishBulkCreate={onFinishBulkCreate}
              openPasswordModal={openPasswordModal}
            />
            <Route path="/meets/:meetId/results" component={ResultsIndex} />
            <AuthRoute
              path="/meets/:meetId/platforms/:platformId/run"
              component={RunIndex}
              openPasswordModal={openPasswordModal}
            />
            <Route
              path="/meets/:meetId/platforms/:platformId/board"
              component={BoardIndex}
            />
            <Route
              path="/meets/:meetId/platforms/:platformId/display"
              component={DisplayIndex}
            />
            <AuthRoute
              path="/meets/:meetId/platforms/:platformId/refs/:position"
              component={RefIndex}
              openPasswordModal={openPasswordModal}
            />
            <Route path="/meets/:meetId/stats" component={StatsIndex} />
            <Route
              path="/meets/:meetId/rackHeights"
              component={RackHeightsIndex}
            />
            <Route
              path="/meets/:meetId/virtualMeetSubmit"
              component={VirtualMeetSubmitIndex}
            />
            <Route path="/meets/:meetId/validator" component={ValidatorIndex} />
            <Route component={RouteNotFound} />
          </Switch>
        )}
        {isLoading && (
          <div className="loading-wrapper">
            <LogoIcon className="logo loop" loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default Meet;
