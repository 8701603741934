import React from "react";
import Modal from "react-modal";

import "./PastDueInvoicesModal.scss";

const PastDueInvoicesModal = ({
  onRequestClose,
  isOpen,
  invoices,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  invoices: { name: string; invoiceUrl: string }[];
}) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel="Past Due Invoices"
      onRequestClose={onRequestClose}
      className={{
        base: "past-due-invoices-modal",
        afterOpen: "past-due-invoices-modal-after-open",
        beforeClose: "past-due-invoices-modal-before-close",
      }}
      overlayClassName={{
        base: "past-due-invoices-modal-overlay",
        afterOpen: "past-due-invoices-modal-overlay-after-open",
        beforeClose: "past-due-invoices-modal-overlay-before-close",
      }}
    >
      <div className="content">
        <div className="title">
          Please pay these past due invoices then try again.
        </div>
        <div className="info">
          {invoices.map((invoice) => {
            return (
              <div
                style={{ marginBottom: 20, marginTop: 20, overflow: "scroll" }}
              >
                <div>
                  <a target="_blank" rel="noreferrer" href={invoice.invoiceUrl}>
                    <div>{invoice.name}</div>
                    <div>{invoice.invoiceUrl}</div>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="button-row">
        <button onClick={onRequestClose} style={{ marginRight: 12 }}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default PastDueInvoicesModal;
