import React from "react";
import { Route, Switch, NavLink, Redirect } from "react-router-dom";

import CardsVersion1 from "./CardsVersion1";
import CardsVersion2 from "./CardsVersion2";
import { useMeet } from "util/useMeet";

const CardsIndex = () => {
  const meet = useMeet();
  const baseUrl = `/meets/${meet._id}`;

  return (
    <div className="cards-index">
      <div className="tabs">
        <NavLink
          to={`${baseUrl}/paperwork/cards/version1`}
          className="tab"
          activeClassName="active"
        >
          Version 1
        </NavLink>
        <NavLink
          to={`${baseUrl}/paperwork/cards/version2`}
          className="tab"
          activeClassName="active"
        >
          Version 2
        </NavLink>
      </div>

      <div className="cards-content">
        <Switch>
          <Route
            path="/meets/:meetId/paperwork/cards/version1"
            component={CardsVersion1}
          />
          <Route
            path="/meets/:meetId/paperwork/cards/version2"
            component={CardsVersion2}
          />
          <Route
            render={() => (
              <Redirect to={`${baseUrl}/paperwork/cards/version1`} />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default CardsIndex;
